// 系统消息
<template>
  <div class="sys_message">
    <!-- 内容部分 -->
    <div class="content" v-if="showType == 2">
      <div class="msg_top">我的消息</div>
      <!-- 列表 -->
      <el-card class="msg_content">
        <!-- 顶部的消息以及操作栏部分 -->
        <div class="msg_info_action">
          <!-- 左边的显示条数和页码 -->
          <div class="left_info">
            <span>共{{ totalSize }}条,每页{{ pageSize }}条</span>,<span>当前{{ pageNum }}/{{ totalPageSize }}页</span>
          </div>
          <!-- 右边的操作部分 学生端不能操作消息管理功能 -->
          <div class="right_action" v-if="roleType != 0">
            <!-- 全选,删除按钮 -->
            <div class="all_del animated fadeIn" v-if="isManager">
              <span class="all" @click.stop="doChooseAction">{{ isAll ? "反选" : "全选" }}</span>
              <span class="del" @click.stop="doDelAction">删除</span>
            </div>
            <!-- 管理按钮 -->
            <span class="manager" @click.stop="doManagerAction">{{ isManager ? "取消" : "管理" }}</span>
          </div>
        </div>
        <div class="msg_list">
          <ItemSysMsg @sysSelectState="sysSelectState" :isManager="isManager" v-for="(item, index) in msgArr" :key="index" :msgItem="item"></ItemSysMsg>
        </div>
      </el-card>
      <!-- 底部分页 -->
      <div class="bom_page">
        <!-- 分页组件 -->
        <el-pagination background layout="prev, pager, next" :total="totalSize" :page-size="pageSize" :current-page="pageNum" @current-change="currChange">
        </el-pagination>
      </div>
    </div>
    <!-- 加载布局 -->
    <Loading v-if="showType == -1"></Loading>

    <!-- 空视图部分 -->
    <el-empty description="暂无消息~" v-if="showType == 1"></el-empty>

    <!-- 删除消息的弹框 -->
    <DelSysMsg ref="delSysMsg" v-if="showDelSysMsg" @close="closeDelMsgDialog" :showDialog="showDelSysMsg" title="删除消息" @delSysMsg="doDelMsg" />
  </div>
</template>
<script>
import DelSysMsg from "../dialog/DelSysMessage.vue";
import ItemSysMsg from "./ItemSysMsg.vue";
export default {
  name: "SysMessage",
  data() {
    return {
      //显示Loading布局 -1Loading 1空视图 2内容视图
      showType: -1,

      //用户角色
      roleType: -1,

      //控制管理状态的标识
      isManager: false,

      //是否是全选的状态
      isAll: false,

      //每页显示的消息数量
      pageSize: 10,

      //当前页码
      pageNum: 1,

      //总的消息数量
      totalSize: 0,

      //总的页码数
      totalPageSize: 0,

      //服务器返回的总的消息数据
      serverMsgArr: [],

      //当前页显示的消息数据
      msgArr: [],

      //是否显示删除消息的弹框
      showDelSysMsg: false
    };
  },

  created() {},

  mounted() {
    //获取用户角色
    this.getRoleType();

    //请求页面数据
    this.http();
  },
  methods: {
    //获取用户角色 0学生 2老师 3校长 4前端管理员
    getRoleType() {
      this.roleType = this.getUserRole();
      this.roleType = 2;
    },
    //请求消息数据
    async http() {
      //获取页面消息
      let param = this.getHttpParams();
      param.type = 12;
      let res = await this.$http.fetchPost(this.$api.SYS_MSG, param);
      if (res.data.state == 200) {
        //表示请求成功
        //获取服务器返回的消息数据
        this.serverMsgArr = res.data.messages;

        if (!this.isEmpty(this.serverMsgArr)) {
          //有数据
          this.showType = 2;

          //初始化当前页面显示的数据
          this.initTotalSize();
        } else {
          //没有数据
          this.showType = 1;
        }
      } else {
        //报错后直接隐藏加载框
        this.showType = -1;
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },
    //根据总的消息数和每页显示的数量计算总页码数
    initTotalSize() {
      //初始化总页面
      this.totalSize = this.serverMsgArr.length;
      this.totalPageSize = this.totalSize % this.pageSize == 0 ? this.totalSize / this.pageSize : Math.ceil(this.totalSize / this.pageSize);

      //初始化当前页面
      this.pageNum = 1;

      //同步当前页的消息数据
      this.sysCurrMsgData();
    },

    //同步当前页的数据
    sysCurrMsgData() {
      //清空当前页数据
      this.msgArr.splice(0, this.msgArr.length);
      let start = (this.pageNum - 1) * this.pageSize;
      let end = start + this.pageSize > this.totalSize ? this.totalSize : start + this.pageSize;
      for (let i = start; i < end; i++) {
        this.msgArr.push(this.serverMsgArr[i]);
      }
      this.msgArr.forEach(item => {
        //添加是否选中的标识
        this.isAll ? this.$set(item, "isSelected", true) : this.$set(item, "isSelected", false);

        //设置时间
        this.$set(item, "time", this.formatDate(item.CREATE_TIME, "yyyy-MM-dd HH:mm"));
      });
    },
    //管理的操作
    doManagerAction() {
      //将管理状态取反
      this.isManager = !this.isManager;
      if (!this.isManager) {
        this.isAll = false;
        //关闭管理功能时同步一下页面数据状态
        this.sysCurrPageState();
      }
    },

    //全选或反选的操作
    doChooseAction() {
      this.isAll = !this.isAll;
      //同步列表的状态
      this.sysCurrPageState();
    },

    //同步当前里列表数据的状态的方法
    sysCurrPageState() {
      this.msgArr.forEach(item => {
        this.isAll ? (item.isSelected = true) : (item.isSelected = false);
      });
    },

    //删除的操作
    doDelAction() {
      if (this.hasMsgSelected()) {
        this.showDelSysMsg = true;
      } else {
        this.showWarnMsg("请先选择需要删除的消息~~");
      }
    },

    //关闭删除消息的弹框
    closeDelMsgDialog() {
      this.showDelSysMsg = false;
    },

    //删除选中的消息的操作
    async doDelMsg() {
      //请求服务器做删除的操作
      //获取页面消息
      let param = this.getHttpParams();
      param.type = 17;
      param.msgs = this.getDelMsgIds();

      let res = await this.$http.fetchPost(this.$api.SYS_MSG, param);

      //改变子组件的Loading
      this.$refs.delSysMsg.showCommitLoading = false;

      if (res.data.state == 200) {
        //将删除消息的对话框关闭
        this.closeDelMsgDialog();
        //重新刷新页面数据
        this.http();
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },

    //判断是否有选中消息
    hasMsgSelected() {
      let res = false;
      for (let i = 0; i < this.msgArr.length; i++) {
        if (this.msgArr[i].isSelected) {
          res = true;
          break;
        }
      }
      return res;
    },

    //获取所有选中消息的msgId
    getDelMsgIds() {
      //获取所有选中的学生
      let allSelectMsg = this.msgArr.filter(item => item.isSelected == true);

      //获取所有选中消息的msgId
      let idsArr = allSelectMsg.map(item => {
        return item.msgId;
      });

      //拼接id
      return idsArr.join("-");
    },

    //还原状态的方法
    resetState() {
      this.isManager = false;
      this.isAll = false;
    },

    //当前页码改变时触发
    currChange(curPage) {
      // console.log("当前改变的页码是:", curPage);
      this.pageNum = curPage;
      //同步当前页的消息数据
      this.sysCurrMsgData();
    },

    //如果当前页面全部选中的话,则顶部的提示变为反选,如果当前页有一个没选中,顶部的提示变为全选
    sysSelectState() {
      if (this.hasAllSelect()) {
        //全部选中
        if (!this.isAll) {
          this.isAll = true;
        }
      } else {
        //不是全部选中
        if (this.isAll) {
          this.isAll = false;
        }
      }
    },

    //判断当前页面是否全部选中的方法
    hasAllSelect() {
      let res = true;
      for (let i = 0; i < this.msgArr.length; i++) {
        if (!this.msgArr[i].isSelected) {
          res = false;
          break;
        }
      }
      return res;
    }
  },
  computed: {},
  components: {
    ItemSysMsg,
    DelSysMsg
  }
};
</script>
<style lang="scss" scoped>
.sys_message {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: $common_tv;
  .content {
    margin: 20px auto;
    flex: 1;
    width: $common_width;
    display: flex;
    flex-direction: column;

    .msg_top {
      margin-bottom: 10px;
      font-weight: bold;
    }

    .msg_content {
      flex: 1;
      display: flex;
      flex-direction: column;

      ::v-deep .el-card__body {
        padding: 16px;
      }
      .msg_info_action {
        margin-top: 10px;
        align-items: center;
        display: flex;
        justify-content: space-between;

        //右边的操作部分
        .right_action {
          display: flex;
          align-items: center;
          //全选,删除
          .all_del {
            margin-right: 20px;
            .all,
            .del {
              color: $common_bg;
              cursor: pointer;
            }
            .all {
              margin-right: 10px;
            }
          }
          //管理
          .manager {
            color: $common_bg;
            cursor: pointer;
          }
        }
      }
      .msg_list {
        flex: 1;
      }
    }

    .bom_page {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: $common_bg;
      }
    }
  }
  ::v-deep .el-empty {
    height: 100%;
  }
}
</style>
