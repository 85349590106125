// 系统消息的条目组件
<template>
  <div class="item_sys_msg">
    <!-- 操作按钮 -->
    <i
      @click.stop="sysItemState"
      v-if="isManager"
      :class="['left_menu', 'iconfont', 'animated', 'fadeIn', msgItem.isSelected ? 'cloud-fangxingxuanzhong' : 'cloud-fangxingweixuanzhong']"
    ></i>
    <!-- 时间 -->
    <span>{{ msgItem.time }}</span>

    <!-- 消息的详情 -->
    <div class="msg_content">
      <!-- 第一种消息类型 邀请加入校区 用户 XXX（13000000000） 邀请您加入校区万松园小学。（老师端） -->
      <div v-if="msgItem.type == 7">
        用户<span class="common_info has_margin">{{ msgItem.content[0] }}</span
        >(<span class="common_info">{{ msgItem.content[1] }}</span
        >)<span class="ml">邀请您加入校区</span><span class="common_info ml">{{ msgItem.content[2] }}</span>
      </div>
      <!-- 第二种消息类型 3创建班级  你创建了班级   XXXXXXXXXXX（班级id：12336543）（老师端） -->
      <div v-else-if="msgItem.type == 3">
        <span class="common_info has_margin">{{ msgItem.content[0] }}</span>
        <span>创建了班级</span><span class="common_info has_margin">{{ msgItem.content[1] }}</span
        >(<span>班级id：{{ msgItem.content[2] }}</span
        >)
      </div>
      <!-- 第三种消息类型 删除班级  你删除了班级   XXXXXXXXXXX（班级id：12336543）（老师端） -->
      <div v-else-if="msgItem.type == 6">
        <span class="common_info has_margin">{{ msgItem.content[0] }}</span>
        <span>删除了班级</span><span class="common_info has_margin">{{ msgItem.content[1] }}</span
        >(<span>班级id：{{ msgItem.content[2] }}</span
        >)
      </div>
      <!-- 第四种消息类型 同意加入班级  你的班级申请已通过；（学生端） -->
      <div v-else-if="msgItem.type == 1">
        <span>你的班级申请已通过。</span>
      </div>
      <!-- 第五种消息类型 拒绝加入班级  你的班级申请已被拒绝，如有疑问请联系班主任！ （学生端）-->
      <div v-else-if="msgItem.type == 2">
        <span>你的班级申请已被拒绝，如有疑问请联系班主任！</span>
      </div>
      <!-- 第六种消息类型  移出班级 你已被班主任移出班级，如有疑问请联系班主任！ （学生端）-->
      <div v-else-if="msgItem.type == 4">
        <span>你已被班主任移出班级，如有疑问请联系班主任！</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    //同步条目的选中状态
    sysItemState() {
      this.msgItem.isSelected = !this.msgItem.isSelected;
      this.$emit("sysSelectState");
    }
  },
  computed: {},
  components: {},
  props: ["msgItem", "isManager"]
};
</script>
<style lang="scss" scoped>
.item_sys_msg {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 20px 0;

  .left_menu {
    cursor: pointer;
    font-size: 16px;
    margin-right: 8px;
  }

  //消息的详情
  .msg_content {
    margin-left: 10px;
    flex: 1;

    .common_info {
      color: $common_bg;
      font-weight: bold;
    }

    .has_margin {
      margin: 0 4px;
    }

    .ml {
      margin-left: 4px;
    }
  }
}
</style>
