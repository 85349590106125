import { render, staticRenderFns } from "./ItemSysMsg.vue?vue&type=template&id=2dd39d02&scoped=true&"
import script from "./ItemSysMsg.vue?vue&type=script&lang=js&"
export * from "./ItemSysMsg.vue?vue&type=script&lang=js&"
import style0 from "./ItemSysMsg.vue?vue&type=style&index=0&id=2dd39d02&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dd39d02",
  null
  
)

export default component.exports